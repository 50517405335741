<template>
<div class="col-md-12" id="register">
      <div class="text-center text-main">
            <h1>Registrera</h1>
      </div>
      <b-container>
            <Stepper :steps="steps" :current="currentstep"></Stepper>
            <div class="p-2 m-4 text-center" v-if="!step1.complete">
                  <Alert show variant="info" >Ange din epostadress och ett lösenord för att bli medlem. En kod
          kommer skickas till din e-postadress så var noga med att ange en
          giltig e-postadress. Först när du har angett koden för att verifiera
          din e-postadresss kan du slutföra din registrering.</Alert>
            </div>
            <div class="card card-container">
                  <div v-if="!step1.complete">
                        <form name="form" @submit.prevent="handleRegister">
                              <Loader v-if="step1.loading" loadingtext="Kontrollerar uppgifter..."></Loader>
                              <div v-else>
                                    <div class="form-group">
                                          <label for="email">Välj en <label class="font-weight-bold">E-postadress</label> för inloggning</label>
                                          <input v-model="step1.user.email" v-validate="'required|email|max:50'" type="email" class="form-control" name="email" />
                                          <Alert v-if="errors.has('email')" variant="danger" >Ange en giltig e-postadress</Alert>
                                    </div>
                                    <div class="form-group">
                                          <label for="password">Välj ditt önskade <label class="font-weight-bold">Lösenord</label> vid inloggningar</label>
                                          <input v-model="step1.user.password" v-validate="'required|min:8|max:40'" type="password" class="form-control" name="password" />
                                          <Alert v-if="errors.has('password')" variant="danger" >Ange ett lösenord på mellan 8 och 40 tecken långt</Alert>
                                    </div>
                                    <div class="form-group">
                                          <button class="btn btn-primary btn-block">
                                                <span>Nästa</span>
                                          </button>
                                          <b-button class="mt-2 btn-block" @click.prevent.stop="Cancel()">Avbryt</b-button>
                                    </div>
                                    <Alert variant="danger" v-if="step1.message" class="text-center" >{{step1.message}}</Alert>
                              </div>
                        </form>
                  </div>

                  <div v-if="step1.complete && !step2.complete" class="text-center">
                        <Loader v-if="codeEntered" loadingtext="Kontrollerar koden..."></Loader>
                        <div v-else>
                              <p class="font-style-italic">
                                    Ange koden som skickades till din e-postadress
                                    {{ step1.user.email }} <span class="text-muted font-italic">(obs kontrollera ev skräppost)</span>
                              </p>
                              <Alert variant="clock" >Du har 5 felaktiga försök eller max 30 minuter på dig</Alert>
                              <div v-if="step2.tries >= 5">
                                    <Alert variant="danger" >Du har uppnått max antal försök!</Alert>
                              </div>
                              <div v-else>
                                    <div class="mt-4 mb-4">
                                          <PincodeInput v-model="step2.code" placeholder="_" :length="6" />
                                    </div>
                                    <div class="mt-2" v-if="step2.message">
                                          <Alert variant="danger" >{{step2.message}}</Alert>
                                    </div>
                              </div>
                              <b-button class="mt-2 btn-block" @click.prevent.stop="Cancel()">Avbryt</b-button>
                        </div>
                  </div>

                  <div v-if="step1.complete && step2.complete && !step3.complete">
                        <Loader v-if="step3.loading" loadingtext="Slutför registrering..."></Loader>
                        <div v-else>
                              <div class="text-center mb-2">
                                    <h3>Slutför registrering</h3>
                              </div>
                              <div class="mt-2">
                                    <label>E-postadress</label>
                                    <div class="form-control locked">{{step3.user.email}}</div>
                              </div>
                              <div class="mt-2">
                                    <label>Lösenord</label>
                                    <div class="form-control locked">********</div>
                              </div>
                              <div class="mt-2">
                                    <label>Användarnamn (synlig för andra)</label>
                                    <b-form-input id="input-2" v-validate="'required|min:2|max:50'" v-model="step3.user.username" name="username" placeholder="Välj användarnamn..." required></b-form-input>
                                    <Alert v-if="errors.has('username')" variant="danger" >Ange ett användarnamn</Alert>
                                    <Alert v-if="step3.errors['username']" variant="danger" >{{step3.errors['username']}}</Alert>
                              </div>
                              <div class="mt-2">
                                    <label>Förnamn (synlig för andra)</label>
                                    <b-form-input id="input-2" v-model="step3.user.name" name="firstname" v-validate="'required|min:2|max:50'" placeholder="Ditt förnamn..." required></b-form-input>
                                    <Alert v-if="errors.has('firstname')" variant="danger" >Ange ett förnamn</Alert>
                                    <Alert v-if="step3.errors['name']" variant="danger" >{{step3.errors['name']}}</Alert>
                              </div>
                              <div class="mt-2">
                                    <label>Efternamn</label>
                                    <b-form-input id="input-2" v-validate="'required|min:2|max:50'" v-model="step3.user.lastname" name="lastname" placeholder="Ditt efternamn..." required></b-form-input>
                                    <Alert v-if="errors.has('lastname')" variant="danger" >Ange ett användarnamn</Alert>
                                    <Alert v-if="step3.errors['lastname']" variant="danger">{{step3.errors['lastname']}}</Alert>
                              </div>
                              <hr />
                              <h3>Valfria uppgifter</h3>
                              <div class="mt-2">
                                    <label>Mobilnummer (för sms-uppdateringar)</label>
                                    <b-form-input id="input-2" v-model="step3.user.phone" placeholder="Ditt mobilnummer..."></b-form-input>
                              </div>
                              <div class="mt-2">
                                    <label>Personnummer (för att visa ålder)</label>
                                    <b-form-input id="input-2" v-model="step3.user.ssnr" placeholder="YYYYMMDD-XXXX"></b-form-input>
                              </div>
                              
                              <div class="mt-5 text-center">
                                    <b-button class="mt-2 btn-block" variant="primary" @click="CompleteRegister()">Skapa</b-button>

                                    <b-button class="mt-2 btn-block" @click.prevent.stop="Cancel()">Avbryt</b-button>
                              </div>

                        </div>
                  </div>

                  <div v-if="step1.complete && step2.complete && step3.complete" class="text-center">
                        <Alert variant="success" class="text-center" >Ditt konto är nu skapat!</Alert>
                        <div class="mt-2">
                              <b-button variant="primary" @click="Go()">Fortsätt</b-button>
                        </div>
                  </div>
            </div>
      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");

import PincodeInput from "vue-pincode-input";
//import CompleteRegister from "@/views/front/CompleteRegister";
import Stepper from "@/components/stepper/Stepper";

export default {
      name: "Register",
      components: {
            Stepper,
            Alert,
            PincodeInput,
            Loader,
            //CompleteRegister
      },
      metaInfo: {
            title: "Registrera konto",
            meta: [{
                  name: "description",
                  content: "Är du inte medlem på kroppskollen.com kan du enkelt registrera ditt egna konto! Det är helt gratis!",
            }, ],
      },
      data() {
            return {
                  steps: [{
                              index: 0,
                              title: "Epost",
                              subtitle: "Skapa en inloggning",
                              icon: "common/mail.svg",
                        },
                        {
                              index: 1,
                              title: "Kod",
                              subtitle: "Ange kod",
                              icon: "common/key.svg",
                        },
                        {
                              index: 2,
                              title: "Slutför",
                              subtitle: "Slutför registrering",
                              icon: "common/check-circle.svg",
                        },
                  ],
                  step1: {
                        // epost
                        user: {
                              email: "",
                              password: "",
                        },
                        loading: false,
                        success: false,
                        complete: false,
                  },
                  step2: {
                        code: "",
                        loading: false,
                        success: false,
                        message: "",
                        complete: false,
                        tries: 0,
                  },
                  step3: {
                        user: {
                              email: "",
                              password: "",
                              username: "",
                              name: "",
                              lastname: "",
                              phone: "",
                              ssnr: ""
                        },
                        loading: false,
                        success: false,
                        complete: false,
                        errors: {}
                  },
            };
      },
      computed: {
            loggedIn() {
                  return this.$store.state.auth.status.loggedIn;
            },
            codeEntered() {
                  // kolla att alla fält är ifyllda samt endast en sifra
                  var valid = this.step2.code.length == 6;
                  if (valid && !this.step2.loading) {
                        this.step2.loading = true;
                        this.validateEnteredCode(this.step2.code);
                  }
                  return valid;
            },
            currentstep() {
                  if (!this.step1.complete) return 0;
                  if (this.step1.complete && !this.step2.complete) return 1;
                  if (this.step1.complete && this.step2.complete && !this.step3.complete)
                        return 2;
                  return 3;
            }           
      },
      mounted() {
            if (this.loggedIn) {
                  this.$router.push("/profil");
            }
      },
      methods: {
            handleRegister() {
                  this.$validator.validate().then((isValid) => {
                        if (isValid) {
                              this.step1.loading = true;
                              this.$store
                                    .dispatch("register/registeraccount", this.step1.user)
                                    .then(() => {
                                          this.step1.complete = true;
                                          this.step1.success = true;
                                          this.step1.loading = false;
                                    })
                                    .catch((error) => {
                                          this.step1.complete = false;
                                          this.step1.success = error;
                                          this.step1.loading = false;
                                    });
                        }
                  });
            },
            CompleteRegister() {
                  this.$validator.validate().then((isValid) => {
                        if (isValid) {
                              this.step3.loading = true;
                              this.step3.errors = [];
                              // skapa
                              var postdata = this.step3.user;
                              postdata.code = this.step2.code;
                              this.$store.dispatch("register/completeRegistration", postdata).then((response) => {
                                    this.step3.loading = false;
                                    this.step3.success = false;
                                    this.step3.errors = [];
                                    this.step3.complete = false;
                                    if (response.data.accessToken) {
                                          localStorage.setItem('user', JSON.stringify(response.data));
                                          this.$store.commit("auth/loginSuccess", response.data);
                                          this.step3.complete = true;
                                          this.step3.success = true;
                                    }
                              }).catch((error) => {
                                    this.step3.complete = false;
                                    this.step3.success = false;
                                    this.step3.errors = error.response.data;
                                    this.step3.loading = false;
                              });
                        }
                  });
            },
            buildPostData() {
                  return this.step3.user;
            },
            validateEnteredCode(code) {
                  // ajax!
                  var email = this.step1.user.email;
                  this.step2.loading = true;
                  this.$store
                        .dispatch("register/validateRegistrationCode", {
                              email: email,
                              code: code,
                        })
                        .then(() => {
                              this.step2.complete = true;
                              this.step2.success = true;
                              this.step2.loading = false;
                              this.step3.user.email = this.step1.user.email;
                              this.step3.user.password = this.step1.user.password;
                        })
                        .catch((error) => {
                              this.step2.loading = false;
                              this.step2.complete = false;
                              this.step2.success = false;
                              this.step2.code = "";
                              this.step2.tries += 1;
                              this.step2.message = error.response.data;
                        });
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            Cancel() {
                  this.$router.push("/");
            },
            Go() {
                  // om allt är färdigt sätter vi token och går till turneringar!
                  this.$router.push("/");
            }
      },
};
</script>
